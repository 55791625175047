<template>
  <div class="bg-pure-white">
    <div
        class="flex"
        :class="[backButtonIsVisible ? 'justify-between' : 'justify-end']"
    >
      <RouterLink
          v-if="backButtonIsVisible"
          class="h-109 ml-39 flex items-center text-charcoal-grey text-base no-underline"
          :to="{
              name: $route.params.previousRouteName,
              params: { locale: $i18n.locale },
          }"
      >
        <IconArrow
            class="text-faded-red cursor-pointer mr-22"
            direction="left"
        />
        <p class="cursor-pointer">
          {{ $t('product_details.back_to_results_text') }}
        </p>
      </RouterLink>
      <div class="mr-40 flex">
        <div
            v-for="(tools, index) in moreToolsConfig"
            :key="index"
            class="flex flex-col items-center h-120 w-90 bg-white mr-2 text-center
            text-sm cursor-pointer"
            :class="tools.classNames"
            @click="tools.onPress ? tools.onPress() : null"
        >
          <a
              :href="tools.document"
              target="_blank"
              class="flex flex-col items-center text-charcoal-grey no-underline"
          >
            <img
                v-if="tools.iconSrc"
                :src="tools.iconSrc"
                alt="Icon"
                class="mb-15 mt-14"
            />
            <Component
                v-else
                :is="tools.iconComponent"
                class="mb-15 mt-14"
            />
            <span class="w-76 h-20">{{ tools.label }}</span>
          </a>
        </div>
      </div>
    </div>
    <p class="w-1/2 ml-40 mr-40 text-xl font-sans-bold text-charcoal-grey">
      {{ processedProductDetails.title }}
    </p>
    <p class="ml-40 mr-40 mt-4 text-lg text-charcoal-grey w-383">
      {{ processedProductDetails.subtitle }}
    </p>
    <p class="ml-40 mr-40 mt-40 text-charcoal-grey leading-29 w-2/3">
      {{ processedProductDetails.description }}
    </p>
    <div class="absolute pin-x ml-40 mr-40 mt-40">
      <div class="flex text-center justify-center cursor-pointer">
        <div
            v-for="(tab, index) in tabsConfig"
            :key="index"
            class="w-1/3 h-70
            text-pure-white flex items-center text-lg pl-23"
            :class="[{ 'border-r border-dark-grey': rightBorderIsVisible(index,
            activeTab, tabsConfig.length) },
              index === activeTab ? 'bg-faded-red' : 'bg-charcoal-grey']"
            @click="switchTab(index)"
        >
          {{ tab.label }}
        </div>
      </div>
    </div>
    <!--Here are tabs-->
    <div class="bg-white mt-77 pt-59 pb-39 text-charcoal-grey">
      <!--First tab-->
      <div
          v-if="activeTab === 0"
          class="flex"
      >
        <div class="pl-63 w-1/2">
          <p class="font-sans-bold">{{ $t('product_details.installation_height_label_text') }}</p>
          <p class="font-sans-bold text-xl pt-3">{{ productDetails.height }} mm</p>
          <p class="font-sans-bold pt-20">{{ $t('product_details.source_label_text') }}</p>
          <p class="pt-5">{{ productDetails.source }}</p>
        </div>
        <div class="w-1/2">
          <p class="font-sans-bold">{{ $t('product_details.mass_label_text') }}</p>
          <p class="font-sans-bold text-xl pt-3">
            {{ productDetails.weight }} kg/m<sup>2</sup>
          </p>
          <p class="font-sans-bold pt-20">Lignum ID-№</p>
          <p class="pt-5">{{ productDetails.id }}</p>
        </div>
      </div>
      <!--Second tab-->
      <div
          v-else-if="activeTab === 1"
          class="flex w-full"
      >
        <div class="pl-63 w-1/2 mr-24 tablet-landscape:mr-45">
          <p class="font-sans-bold">{{ $t('product_details.airborne_sound_label_text') }}</p>
          <p class="font-sans-bold text-xl pt-3">
            R<sub>w</sub> {{ productDetails.rw }} dB
          </p>
          <div class="flex flex-wrap flex-row pt-5">
            <p class="font-light mr-2">{{ $t('product_details.adaptation_value_text') }}</p>
            <p>
              {{ adaptionConfig.common.rw.label }}:
              {{ adaptionConfig.common.rw.valueFirst }} dB
            </p>
            <p
                v-if="adaptionConfig[productType]"
                class="ml-4 mr-4"
            >
              |
            </p>
            <p v-if="adaptionConfig[productType]">
              <!--eslint-disable-next-line max-len-->
              {{ adaptionConfig[productType].rw.label }}<sub>{{ adaptionConfig[productType].rw.subLabel }}</sub>:
              {{ adaptionConfig[productType].rw.valueSecond }} dB
            </p>
          </div>
          <div v-if="productDetails.certificate">
            <p class="font-sans-bold pt-20">
              {{ $t('product_details.verified_calculation_label_text') }}
            </p>
            <p class="pt-5">{{ $t('product_details.learn_acustic_label_text') }}</p>
            <div class="mt-15">
              <a
                  class="text-faded-red no-underline"
                  :href="productDetails.certificate"
                  target="_blank"
              >
                {{ $t('product_details.certificate_label_text') }}
              </a>
            </div>
          </div>
        </div>
        <div
            v-if="productDetails.lnw !== 0"
            class="w-1/2 pr-20"
        >
          <p class="font-sans-bold">{{ $t('product_details.footfall_label_text') }}</p>
          <p class="font-sans-bold text-xl pt-3">
            L<sub>n,w</sub>: {{ productDetails.lnw }} dB
          </p>
          <div class="flex flex-wrap pt-5">
            <p class="font-light mr-2">{{ $t('product_details.adaptation_value_text') }}</p>
            <p>
              {{ adaptionConfig[productType].lnw.label }}:
              {{ adaptionConfig[productType].lnw.valueFirst }} dB
            </p>
            <p class="ml-4 mr-4">|</p>
            <!--eslint-disable-next-line max-len-->
            <p>{{ adaptionConfig[productType].lnw.label }}<sub>{{ adaptionConfig[productType].lnw.subLabel }}</sub>:
              {{ adaptionConfig[productType].lnw.valueSecond }} dB</p>
          </div>
        </div>
      </div>
      <!--Third tab-->
      <div
          v-else
          class="flex flex-col pr-60"
      >
        <div
            v-for="(fireClass, index) in processedProductDetails.fireResistanceClasses"
            :key="index"
            class="flex flex-row mb-24 w-full"
        >
          <p class="w-1/3 ml-63 mr-20 tablet-landscape:mr-32">
            {{ fireClass.name || '-' }}
          </p>
          <p class="w-1/3 mr-20 tablet-landscape:mr-37">
            {{ fireClass.source || '-' }}
          </p>
          <p class="w-2/3">
            {{ fireClass.additionalRequirements || '-' }}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-81 mb-81 pl-100 pr-100 items-center text-center">
      <img
          :src="productDetails.image"
          :alt="processedProductDetails.title"
      >
    </div>
    <div class="mt-40 mb-40 border-b border-white"></div>
    <p class="ml-40 mb-29 text-lg font-sans-bold">
      {{ $t('product_details.layer_structure_label_text') }}
    </p>
    <!--Here is product details layer-->
    <div class="mb-70">
      <div
          v-for="(layer, index) in productLayers"
          :key="index"
          class="ml-40 mb-30"
      >
        <p class="text-lg text-charcoal-grey mb-13">{{ layer.title }}</p>
        <div class="border-b border-white"></div>
        <div class="flex flex-wrap">
          <div
              v-for="(value, key) in layer.data"
              :key="key"
              class="w-219 mt-19 mr-22"
          >
            <p class="text-xs font-sans-bold text-cool-grey"
            >
              {{ value.label }}
            </p>
            <p class="text-base text-charcoal-grey mt-4">
              {{ value.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--End of product details layer-->
    <div class="ml-40 mb-30">
      <div v-if="enabledToolsOption.length !== 0">
        <div class="text-lg font-sans-bold mb-9">TOOLS</div>
        <p>{{ $t('product_details.export_building_text') }}</p>
        <div class="flex flex-row flex-wrap mb-50 mt-30">
          <ToolCard
              v-if="productDetails.enabledTools.includes('uwert')"
              :image-src="'/images/tools/u-value.svg'"
              :show-more-text="false"
              :title="$t('product_details.first_tool_title_text')"
              :link="`https://service.swisskrono.de/api/v1/tools/uwert?laufnummer=${productDetails.id}&lang=${$i18n.locale}`"
          />
          <ToolCard
              v-if="productDetails.enabledTools.includes('tauwasser')"
              :image-src="'/images/tools/condensation.svg'"
              :show-more-text="false"
              :title="$t('product_details.second_tool_title_text')"
              :link="`https://service.swisskrono.de/api/v1/tools/tauwasser?laufnummer=${productDetails.id}&lang=${$i18n.locale}`"
          />
          <ToolCard
              v-if="productDetails.enabledTools.includes('statik')"
              :image-src="'/images/tools/structural-analysis.svg'"
              :show-more-text="false"
              :title="$t('product_details.third_tool_title_text')"
              :link="`https://service.swisskrono.de/api/v1/tools/statiktool?laufnummer=${productDetails.id}&lang=${$i18n.locale}`"
          />
        </div>
      </div>
      <div
          v-if="processedProductDetails.link1 || processedProductDetails.link2 || processedProductDetails.link3"
          class="flex flex-row text-charcoal-grey mb-50"
      >
        <div class="w-307 mr-49 tablet-landscape:w-383">
          <p class="text-lg font-sans-bold mb-6">
            {{ $t('product_details.more_informations_title_text') }}
          </p>
          <p class="h-52">{{ $t('product_details.learn_more_text') }}</p>
          <div
              v-if="processedProductDetails.link1"
              class="flex flex-row"
          >
            <a
                class="no-underline"
                :href="processedProductDetails.link1"
                target="_blank"
            >
              <div class="flex flex-row">
                <div class="mb-13 text-charcoal-grey"> SWISS KRONO OSB/3 EN300</div>
                <IconArrow
                    class="text-faded-red cursor-pointer ml-10"
                    direction="right"
                />
              </div>
            </a>
          </div>
          <div
              v-if="processedProductDetails.link2"
              class="flex flex-row"
          >
            <a
                class="no-underline"
                :href="processedProductDetails.link2"
                target="_blank"
            >
              <div class="flex flex-row">
                <div class="mb-13 text-charcoal-grey">SWISS KRONO OSB/4 EN300</div>
                <IconArrow
                    class="text-faded-red cursor-pointer ml-10"
                    direction="right"
                />
              </div>
            </a>
          </div>
          <div
              v-if="processedProductDetails.link3"
              class="flex flex-row"
          >
            <a
                class="no-underline"
                :href="processedProductDetails.link3"
                target="_blank"
            >
              <div class="flex flex-row">
                <div class="text-charcoal-grey">SWISS KRONO MAGNUMBOARD OSB</div>
                <IconArrow
                    class="text-faded-red cursor-pointer ml-10"
                    direction="right"
                />
              </div>
            </a>
          </div>
        </div>
        <div
            v-if="processedProductDetails.processingInstructions"
            class="w-339 tablet-landscape:w-383"
        >
          <div class="text-lg font-sans-bold mb-6">
            {{ $t('product_details.information_on_use_more_text') }}
          </div>
          <p class="h-52">{{ $t('product_details.find_important_informations_text') }}</p>
          <div class="flex flex-row">
            <a
                class="no-underline"
                :href="processedProductDetails.processingInstructions"
                target="_blank"
            >
              <div class="flex flex-row">
                <div class="text-charcoal-grey">{{$t('product_details.download_pdf')}}</div>
                <IconArrow
                    class="text-faded-red cursor-pointer ml-10"
                    direction="right"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <BookmarkModal :product="productDetails" />

    <SearchModal />
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { defaultLocale, availableLocales } from '@/i18n';
import { FBFirestoreRef } from '@/helpers/firebaseServicesHelper';
import IconArrow from '@/components/icon/IconArrow';
import IconBookmark from '@/components/icon/IconBookmark';
import BookmarkModal from '@/components/bookmark/BookmarkModal';
import ToolCard from '@/components/ToolCard';
import adaptationLabels from '@/helpers/adaptationLabelsHelper';
import { rightBorderIsVisibleForItemIndex } from '@/helpers/uiItemsHelper';
import SearchModal from '@/components/search/SearchModal';


const productDataConfig = [{
  translationKey: 'product_text',
  valueKey: 'productName',
}, {
  translationKey: 'manufacturer_text',
  valueKey: 'manufacturer',
}, {
  translationKey: 'thickness_text',
  valueKey: 'thickness',
}, {
  translationKey: 'weight_text',
  valueKey: 'weight',
}, {
  translationKey: 'width_text',
  valueKey: 'width',
}, {
  translationKey: 'mast_distance_text',
  valueKey: 'mastDistance',
}];

export default {
  components: {
    IconArrow,
    IconBookmark,
    BookmarkModal,
    ToolCard,
    SearchModal,
  },
  data() {
    const productDetails = {};
    availableLocales.forEach((availableLocale) => {
      productDetails[availableLocale] = {
        title: '',
        layers: [],
      };
    });

    return {
      backButtonIsVisible: this.$route.params.allowBack,
      productType: this.$route.params.productType,
      adaptationValues: '',
      productDetails,
      activeTab: 0,
      enabledToolsOption: '',
    };
  },
  created() {
    FBFirestoreRef
      .collection('products')
      .doc('types')
      .collection(this.$route.params.productType)
      .doc(this.$route.params.productId)
      .get()
      .then((doc) => {
        const docData = doc.data();
        docData.id = doc.id;
        this.productDetails = docData;
        this.enabledToolsOption = docData.enabledTools ? docData.enabledTools : '';

        // Updating product name for the view title
        let currentLocale = defaultLocale;
        if (Object.prototype.hasOwnProperty.call(this.productDetails, this.$i18n.locale)) {
          currentLocale = this.$i18n.locale;
        }
        this.updateProductName(this.productDetails[currentLocale].title);

        return Promise.resolve(docData);
      })
      .catch(error => Promise.reject(error));

    FBFirestoreRef
      .collection('types')
      .doc(this.$route.params.productType)
      .get()
      .then((doc) => {
        this.adaptationValues = doc.data().mainFilters;
      });
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newValue) {
        let currentLocale = defaultLocale;
        if (Object.prototype.hasOwnProperty.call(this.productDetails, newValue)) {
          currentLocale = newValue;
        }
        this.updateProductName(this.productDetails[currentLocale].title);
      },
    },
  },
  computed: {
    ...mapState('user/bookmarks', {
      userBookmarksBookmarkedProducts: 'bookmarkedProducts',
    }),
    ...mapGetters('user', {
      userIsAuthenticated: 'isAuthenticated',
    }),
    productId() {
      return this.$route.params.productId;
    },
    productIsBookmarked() {
      return this.userBookmarksBookmarkedProducts
        .findIndex(bookmarkedProduct => bookmarkedProduct.id === this.productDetails.id) !== -1;
    },
    moreToolsConfig() {
      let productBookmarkLabel = this.$t('product_details.save_bookmark_text');
      if (this.productIsBookmarked) productBookmarkLabel = this.$t('product_details.saved_bookmark_text');

      let tools = [{
        label: productBookmarkLabel,
        iconComponent: IconBookmark,
        onPress: this.onBookmarkPress,
        classNames: this.productIsBookmarked ? 'text-faded-red' : 'text-charcoal-grey',
      }, {
        label: this.$t('product_details.download_ifc_text'),
        iconSrc: '/images/icons/product-details/icons-download-3-d.svg',
        document: this.productDetails.ifc,
        classNames: 'text-charcoal-grey',
      }];

      if(this.processedProductDetails.link_extra1){
        tools.push({
          label: this.$t('product_details.specifications_text'),
          iconSrc: '/images/icons/product-details/specifications.svg',
          document: this.processedProductDetails.link_extra1,
          classNames: 'text-charcoal-grey',
        })
      }
      if(this.processedProductDetails.link_extra2){
          tools.push({
          label: this.$t('product_details.specifications_extra'),
          iconSrc: '/images/icons/product-details/specifications.svg',
          document: this.processedProductDetails.link_extra2,
          classNames: 'text-charcoal-grey',
        })
      }

      return tools;
    },
    tabsConfig() {
      return [{
        label: this.$t('product_details.first_tab_label_text'),
      }, {
        label: this.$t('product_details.second_tab_label_text'),
      }, {
        label: this.$t('product_details.third_tab_label_text'),
      }];
    },
    processedProductDetails() {
      return this.productDetails[this.$i18n.locale] || {};
    },
    productLayers() {
      if (!this.productDetails) return false;

      return this.productDetails[this.$i18n.locale].layers
        .map(layerData => ({
          title: layerData.title,
          data: productDataConfig.map(config => ({
            label: this.$t(`product_details.data_labels.${config.translationKey}`),
            value: layerData[config.valueKey],
          })),
        }));
    },
    adaptionConfig() {
      return {
        common: {
          rw: {
            label: adaptationLabels.rw.c100rw.label,
            valueFirst: this.productDetails.c100rw,
          },
        },
        decke: {
          rw: {
            label: adaptationLabels.rw.c50rw.label,
            subLabel: adaptationLabels.rw.c50rw.subLabel,
            valueSecond: this.productDetails.c50rw,
          },
          lnw: {
            label: adaptationLabels.lnw.ci100lnw.label,
            subLabel: adaptationLabels.lnw.ci50lnw.subLabel,
            valueFirst: this.productDetails.ci100lnw,
            valueSecond: this.productDetails.ci50lnw,
          },
        },
        aussenwand: {
          rw: {
            label: adaptationLabels.rw.ctr100rw.label,
            subLabel: adaptationLabels.rw.ctr100rw.subLabel,
            valueSecond: this.productDetails.ctr100rw,
          },
        },
        steildach: {
          rw: {
            label: adaptationLabels.rw.ctr100rw.label,
            subLabel: adaptationLabels.rw.ctr100rw.subLabel,
            valueSecond: this.productDetails.ctr100rw,
          },
        },
      };
    },
  },
  methods: {
    ...mapActions('ui', {
      updateModalState: 'updateModalState',
    }),
    ...mapActions('productDetails', {
      resetProductName: 'resetProductName',
      updateProductName: 'updateProductName',
    }),
    switchTab(tabIndex) {
      this.activeTab = tabIndex;
    },
    rightBorderIsVisible(itemIndex, activeItemIndex, itemCount) {
      return rightBorderIsVisibleForItemIndex(itemIndex, activeItemIndex, itemCount);
    },
    onBookmarkPress() {
      if (this.userIsAuthenticated) {
        this.updateModalState({ isVisible: true, type: 'save-bookmarked-product' });
        return;
      }

      this.updateModalState({ isVisible: true, type: 'log-in' });
    },
  },
  beforeDestroy() {
    this.resetProductName();
  },
};
</script>


<style lang="scss" scoped>
</style>
